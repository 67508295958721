<template>
  <div>供需</div>
</template>

<script>
export default {
  name: "business",
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
</style>